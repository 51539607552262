import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'

export interface ExportEventParams {
  from: string
  to: string
  include: ('all' | 'vehicle' | 'delay' | 'crew' | 'cancel')[]
}
export interface ExportEventParamsRaw {
  from: string
  to: string
}

export interface ExportEventRawResponse {
  deviationCancel: unknown[]
  deviationVehicle: unknown[]
  deviationCrew: unknown[]
  deviationDelay: unknown[]
}

export function exportEvent(
  params: ExportEventParams
): Promise<AxiosResponse<{ [key: string]: unknown }[]>> {
  return instance.get(`export/event/avik1`, {
    params,
  })
}
export function exportEventRaw(
  params: ExportEventParamsRaw
): Promise<AxiosResponse<ExportEventRawResponse>> {
  return instance.get(`export/event-raw`, { params })
}
