
import {
  validDateRule,
  required as requiredRule,
} from '@/common/formValidationRules'
import QForm from 'quasar/src/components/form/QForm.js';
import { computed, defineComponent, ref } from 'vue'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { format, addDays, isAfter, isEqual } from 'date-fns'
import { exportExcel } from '@/services/export-excel'
import { exportEvent, ExportEventParams } from '@/api/export/exportEvent'

export default defineComponent({
  name: 'ExportDeviationListModal',

  components: {
    AppFieldDate,
  },

  emits: ['close'],

  setup(props, { emit }) {
    const model = ref<ExportEventParams>({
      from: format(new Date(), 'yyyy-MM-dd'),
      to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
      include: ['all'],
    })
    const exporting = ref(false)

    const formRef = ref<QForm | null>(null)

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      exporting.value = true

      const { data } = await exportEvent(model.value)

      const exportData = {
        name: 'Avvikelser',
        sheets: [
          {
            name: 'Avvikelser',
            data: data.reduce<{ [key: string]: unknown }[]>((acc, item) => {
              const keys = Object.keys(item)
              acc.push(
                Object.keys(item).reduce<{ [key: string]: unknown }>(
                  (acc, key) => {
                    acc[key] = item[key]

                    if (key === 'OTN2' && !keys.includes('Från')) {
                      acc['Från'] = ''
                    }
                    if (key === 'OTN2' && !keys.includes('Till')) {
                      acc['Till'] = ''
                    }
                    return acc
                  },
                  {}
                )
              )

              return acc
            }, []),
          },
        ],
      }

      exportExcel(exportData)

      exporting.value = false

      emit('close')
    }

    const validDate = computed(() => {
      return (
        isAfter(new Date(model.value.to), new Date(model.value.from)) ||
        isEqual(new Date(model.value.to), new Date(model.value.from))
      )
    })

    const impacts = [
      {
        name: 'Alla',
        key: 'all',
      },
      {
        name: 'Fordonsavvikelser',
        key: 'vehicle',
      },
      {
        name: 'Inställda tåg',
        key: 'cancel',
      },
      {
        name: 'Bemanning',
        key: 'crew',
      },
      {
        name: 'Förseningar',
        key: 'delay',
      },
    ]

    return {
      exporting,
      onSubmit,
      formRef,
      validDateRule,
      requiredRule,
      model,
      validDate,
      impacts,
    }
  },
})
